<template>
  <p>Page Settings Xadmin ChannelSubscription</p>
</template>


<script>
export default {
  name: 'PageSettingsXadminChannelSubscription'
}
</script>
